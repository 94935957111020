import React from "react";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

import LogoContainer from "./LogoContainer";

const HeaderContainer = styled.div`
  position: relative;
  margin-left: 2px;
  margin-right: 3px;
  width: calc(100% - 5px);
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const Header = (): JSX.Element => {
  return (
    <React.Fragment>
      <Navbar bg="secondary" variant="dark" style={{ padding: 0 }}>
        <HeaderContainer>
          <Navbar.Brand href="#" style={{ padding: 0 }}>
            <LogoContainer />
          </Navbar.Brand>
        </HeaderContainer>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
