import React from "react";
import { Button, Nav } from "react-bootstrap";
import styled from "styled-components";

import { linksObject } from "../landPageConfig";

const StyledButtonComponent = styled.div`
  /* height: calc(100vh - 75px - 57px); */
  margin-top: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  button {
    min-width: 15.6rem;
  }
`;

const H1 = styled.h1`
  text-align: center;
  padding: 1rem;
`;

const ButtonContainer = (): JSX.Element => {
  const buttonsColumn = linksObject.map((object, index) => {
    return (
      <Button
        variant={`${object.variant}`}
        size="lg"
        key={index + object.url}
        disabled={object.disabled}
        // href={object.url}
        // target="_blank"
        // rel="noreferrer"
        as="button"
      >
        <Nav.Link href={object.url} target="_blank" rel="noreferrer">
          {object.name}
        </Nav.Link>
      </Button>
    );
  });

  return (
    <React.Fragment>
      <StyledButtonComponent>
        <H1>Choose a location</H1>
        {buttonsColumn}
      </StyledButtonComponent>
    </React.Fragment>
  );
};

export default ButtonContainer;
