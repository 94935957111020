import React from "react";

const MapCircleIcon = ({ color }: { color: string }): JSX.Element => {
  return (
    <React.Fragment>
      <svg
        version="1.1"
        viewBox="0 0 16 16"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <circle cx="8" cy="8" r="8" fill={color} />
      </svg>
    </React.Fragment>
  );
};

export default MapCircleIcon;
