import React from "react";
import styled from "styled-components";

import logo from "../assets/TA.png";

const LogoDiv = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
`;

const Img = styled.img`
  background-color: rgba(255, 255, 255, 0.4);
  width: auto;
  height: 75px;
`;

const H1 = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 1rem;
`;

const LogoContainer = (): JSX.Element => {
  return (
    <React.Fragment>
      <LogoDiv>
        <Img src={logo} alt="logo" />
        <H1>Track Trolley Landing Page</H1>
      </LogoDiv>
    </React.Fragment>
  );
};

export default LogoContainer;
