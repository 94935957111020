import React from "react";

import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ButtonContainer from "./components/ButtonContainer";
import MapComponent from "./components/MapComponent";
import { getActiveBuses, initialData } from "./helpers/actions";
import { fetchInterval } from "./landPageConfig";

function App(): JSX.Element {
  const [mapRange, setMapRange] = React.useState<MapRange | null>(null);
  const [agencyArray, setAgencyArray] = React.useState<AgencyArray[] | null>(null);
  const [agencyDataArray, setAgencyDataArray] = React.useState<BusInfo[] | null>(null);
  // console.log("agencyDataArray:", agencyDataArray, new Date().toLocaleString());

  React.useEffect(() => {
    const getInitialData = async () => {
      const initialDataToSet = await initialData();
      // console.log("initialDataToSet:", initialDataToSet);
      const mapRangeFromApi = initialDataToSet?.initialMapRange as MapRange;
      const agencyArrayFromApi = initialDataToSet?.agencyArray;
      setMapRange(mapRangeFromApi);
      setAgencyArray(agencyArrayFromApi);
    };
    getInitialData();
  }, []);

  React.useEffect(() => {
    if (agencyArray) {
      //* promise.AllSettled unnecessary here
      const getAllBuses = async () => {
        const busesObjectArray = [] as BusInfo[];
        for (let i = 0; i < agencyArray.length; i++) {
          const busesFromApi = await getActiveBuses(agencyArray[i].agencyId);
          // console.log(
          //   "busesFromApi:",
          //   busesFromApi,
          //   new Date().toLocaleString("pl-PL", {
          //     year: "numeric",
          //     month: "numeric",
          //     day: "numeric",
          //     hour: "numeric",
          //     minute: "numeric",
          //     second: "numeric",
          //     fractionalSecondDigits: 3,
          //   })
          // );
          await busesObjectArray.push(...busesFromApi);
        }
        // console.log("Data was fetched at", new Date().toLocaleString());
        setAgencyDataArray(busesObjectArray);
      };
      getAllBuses();
      const fetchingInterval = setInterval(getAllBuses, fetchInterval) as NodeJS.Timer;
      // console.log({ fetchingInterval });
      return () => {
        clearInterval(fetchingInterval);
        // console.log("Interval was cleared");
      };
    }
  }, [agencyArray]);

  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <ButtonContainer />
          </div>
          <div className="col-9">
            <MapComponent mapRange={mapRange!} agencyDataArray={agencyDataArray!} agencyArray={agencyArray!} />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
