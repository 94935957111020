import axios from "axios";
import * as turf from "@turf/turf";

import { apiKey, apiBaseURL } from "../landPageConfig";

export const initialData = async () => {
  let dataToReturn = {
    statusCode: "",
    currentTime: 0,
    agencyArray: [] as AgencyArray[],
    initialMapRange: {},
  };
  await axios
    .get(`${apiBaseURL}/api/where/agencies-with-coverage.json?key=${apiKey}`)
    .then(({ data }) => {
      // console.log("data:", data);
      const statusCode = data.code;
      const currentTime = data.currentTime;

      //* Area for all agencies
      const agencyArray: AgencyArray[] = [];
      for (let i = 0; i < data.data.references.agencies.length; i++) {
        const agencyObject = {
          agencyId: data.data.references.agencies[i].id,
          agencyName: data.data.references.agencies[i].name,
        };
        agencyArray.push(agencyObject);
      }
      const areaArray = [];
      for (let i = 0; i < data.data.list.length; i++) {
        const singleAreaArray = [
          [data.data.list[i].lat - data.data.list[i].latSpan / 2, data.data.list[i].lon - data.data.list[i].lonSpan / 2],
          [data.data.list[i].lat + data.data.list[i].latSpan / 2, data.data.list[i].lon + data.data.list[i].lonSpan / 2],
        ];
        areaArray.push(singleAreaArray);
      }
      const flatAreaArray = areaArray.flat(1);
      // console.log("flatAreaArray:", flatAreaArray);

      const multiPoint = turf.multiPoint(flatAreaArray);
      const bBox = turf.bbox(multiPoint);
      const bboxPolygon = turf.bboxPolygon(bBox);
      const areaPolygon = bboxPolygon?.bbox;
      // console.log("areaPolygon:", areaPolygon);
      const lat = (areaPolygon![0] + areaPolygon![2]) / 2;
      const lon = (areaPolygon![1] + areaPolygon![3]) / 2;
      const latSpan = Math.abs(areaPolygon![2] - areaPolygon![0]);
      const lonSpan = Math.abs(areaPolygon![3] - areaPolygon![1]);
      const initialMapRange = { lat, latSpan, lon, lonSpan };
      // console.log({ statusCode, currentTime, agencyArray, initialMapRange });
      dataToReturn = { statusCode, currentTime, agencyArray, initialMapRange };
    })
    .catch((error) => {
      console.log("error:", error);
    });
  return dataToReturn;
};

export const getActiveBuses = async (agencyId: string) => {
  let dataToReturn: BusInfo[] = [];
  await axios
    .get(`${apiBaseURL}/api/where/vehicles-for-agency/${agencyId}.json?key=${apiKey}`)
    .then(({ data }) => {
      // console.log("data:", data);
      const activeBuses = data?.data?.list;
      for (let i = 0; i < activeBuses.length; i++) {
        activeBuses[i].agencyId = agencyId;
      }
      // console.log("activeBuses:", activeBuses);
      dataToReturn = [...activeBuses];
    })
    .catch((error) => {
      console.log("error:", error);
    });
  return dataToReturn;
};
