import React from "react";
import styled from "styled-components";
import Leaflet, { Map } from "leaflet";
import { MapContainer, TileLayer, ZoomControl, ScaleControl, Rectangle, Marker, Tooltip, Popup } from "react-leaflet";

import { zoomBusOnlyCircle, zoomRange } from "../landPageConfig";
import { createBusIcon, createCircleIcon } from "../helpers/mapIcons";

export const MapWrapper = styled.div`
  height: calc(100vh - 75px - 57px);
  width: 100%;
`;

const rectangleOptions = {
  color: "gray",
  stroke: false,
  fillOpacity: 0.2,
  interactive: false,
  bubblingMouseEvents: false,
};

const PopupDiv = styled.div`
  p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 0;
    margin-top: 0;
    gap: 0.25rem;
    span {
      font-weight: bold;
    }
  }
`;

const MapComponent = ({
  mapRange,
  agencyDataArray,
  agencyArray,
}: {
  mapRange: MapRange;
  agencyDataArray: BusInfo[];
  agencyArray: AgencyArray[];
}): JSX.Element => {
  const [range1, setRange1] = React.useState<LanLngPoint>({ lat: 0, lng: 0 });
  const [range2, setRange2] = React.useState<LanLngPoint>({ lat: 0, lng: 0 });
  const [busesIconsData, setBusesIconsData] = React.useState<BusInfo[] | null>(null);
  const [mapView, setMapView] = React.useState<Map | null>(null);
  const [zoom, setZoom] = React.useState<number | null>(null);
  // console.log("busesIconsData:", busesIconsData);

  React.useEffect(() => {
    if (!mapView) return;
    if (mapView) {
      const zoomToSet = mapView.getZoom();
      setZoom(zoomToSet);
    }
  }, [mapView]);

  React.useEffect(() => {
    if (!mapView) return;
    mapView.on("zoomend", function () {
      const zoomToSet = mapView.getZoom();
      setZoom(zoomToSet);
    });
  }, [mapView]);

  React.useEffect(() => {
    if (mapRange && Object.keys(mapRange).length > 0) {
      setRange1({ lat: mapRange.lat - mapRange.latSpan / 2, lng: mapRange.lon - mapRange.lonSpan / 2 });
      setRange2({
        lat: mapRange.lat + mapRange.latSpan / 2,
        lng: mapRange.lon + mapRange.lonSpan / 2,
      });
    }
  }, [mapRange]);

  React.useEffect(() => {
    if (agencyDataArray && agencyArray) {
      for (let i = 0; i < agencyDataArray.length; i++) {
        for (let k = 0; k < agencyArray.length; k++) {
          if (agencyDataArray[i].agencyId === agencyArray[k].agencyId) {
            agencyDataArray[i].agencyName = agencyArray[k].agencyName;
          }
        }
      }
      setBusesIconsData(agencyDataArray);
      // console.log("Data has been set", new Date().toLocaleString());
    }
  }, [agencyArray, agencyDataArray]);

  const bounds = Leaflet.latLngBounds([range1, range2]);
  // console.log({ bounds });

  // Buses Icons - selected + all together
  const BusesIconsComponent = (busesIcons: BusInfo[]): JSX.Element => {
    return (
      <React.Fragment>
        {busesIcons.map((elem: BusInfo, index) => {
          return (
            <Marker
              key={elem?.vehicleId + index}
              position={{
                lat: elem?.location?.lat as number,
                lng: elem?.location?.lon as number,
              }}
              icon={createBusIcon(elem.agencyName, elem?.tripStatus)}
              // eventHandlers={{ click: () => console.log(elem.agencyName) }}
            >
              <Tooltip permanent={false}>{elem?.agencyName || "No data"}</Tooltip>
              <Popup>
                <PopupDiv>
                  <p>
                    Agency Name: <span>{elem?.agencyName || "No data"}</span>
                  </p>
                  <p>
                    Trip Id: <span>{elem?.tripId || "No data"}</span>
                  </p>
                  <p>
                    Vehicle Id: <span>{elem?.vehicleId || "No data"}</span>
                  </p>
                </PopupDiv>
              </Popup>
            </Marker>
          );
        })}
      </React.Fragment>
    );
  };

  const BusesCirclesComponent = (busesIcons: BusInfo[]): JSX.Element => {
    return (
      <React.Fragment>
        {busesIcons.map((elem: BusInfo, index) => {
          return (
            <Marker
              key={elem?.vehicleId + index}
              position={{
                lat: elem?.location?.lat as number,
                lng: elem?.location?.lon as number,
              }}
              icon={createCircleIcon(elem.agencyName)}
            />
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {range1.lat && range1.lng && range2.lat && range2.lng && (
        <MapWrapper>
          <MapContainer
            ref={setMapView}
            zoomSnap={0.1}
            zoomDelta={0.2}
            maxZoom={18}
            minZoom={5}
            bounds={bounds}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "100%", position: "relative" }}
            zoomControl={false}
            doubleClickZoom={false}
            tap={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Rectangle
              className="rectangleClass"
              bounds={[
                [range1.lat - zoomRange, range1.lng - zoomRange],
                [range2.lat + zoomRange, range2.lng + zoomRange],
              ]}
              pathOptions={rectangleOptions}
            />
            {zoom && zoom >= zoomBusOnlyCircle ? (
              <React.Fragment> {busesIconsData && BusesIconsComponent(busesIconsData)}</React.Fragment>
            ) : (
              <React.Fragment> {busesIconsData && BusesCirclesComponent(busesIconsData)}</React.Fragment>
            )}

            <ZoomControl position="bottomleft" />
            <ScaleControl position="bottomright" />
          </MapContainer>
        </MapWrapper>
      )}
    </React.Fragment>
  );
};

export default MapComponent;
