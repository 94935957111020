import React from "react";
import * as ReactDOMServer from "react-dom/server";
import Leaflet from "leaflet";

import { linksObject } from "../landPageConfig";
import MapBusIcon from "../components/MapBusIcon";
import MapCircleIcon from "../components/MapCircleIcon";

export const createBusIcon = (agencyName: string, tripStatus: { activeTripId: string } | null): Leaflet.DivIcon => {
  const colorObject = linksObject.find((object) => agencyName.includes(object.name));

  return Leaflet.divIcon({
    iconAnchor: [11, 11],
    popupAnchor: [0, -11],
    iconSize: [22, 22],
    tooltipAnchor: [11, 0],
    className: "bus_icon",
    html: ReactDOMServer.renderToString(
      <React.Fragment>
        {colorObject && Object.keys(colorObject).length >= 1 && (
          <MapBusIcon color={colorObject?.color} tripStatus={tripStatus ? true : false} />
        )}
      </React.Fragment>
    ),
  });
};

export const createCircleIcon = (agencyName: string): Leaflet.DivIcon => {
  const colorObject = linksObject.find((object) => agencyName.includes(object.name));

  return Leaflet.divIcon({
    iconAnchor: [6, 6],
    iconSize: [12, 12],
    tooltipAnchor: [0, -6],
    className: "bus_icon_circle",
    html: ReactDOMServer.renderToString(
      <React.Fragment>
        {colorObject && Object.keys(colorObject).length >= 1 && <MapCircleIcon color={colorObject?.color} />}
      </React.Fragment>
    ),
  });
};
