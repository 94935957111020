import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 57px;
`;

const Footer = (): JSX.Element => {
  return (
    <React.Fragment>
      <FooterContainer>
        <footer className="d-flex flex-wrap justify-content-center border-bottom bg-secondary text-white py-3">
          <div>
            &copy; {new Date().getFullYear()} Copyright:{" "}
            <a className="text-dark" href="https://goeuropa.eu" target="_blank" rel="noreferrer">
              <span className="span_bold">goEuropa</span>
            </a>
          </div>
        </footer>
      </FooterContainer>
    </React.Fragment>
  );
};

export default Footer;
