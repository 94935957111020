export const apiBaseURL = "https://oba.tracktrolley.com/oba-api";
export const zoomRange = 0.005;
export const apiKey = "org.onebusaway.iphone";
export const fetchInterval = 60 * 1000;
export const zoomBusOnlyCircle = 11.5;

export const linksObject = [
  {
    url: "https://sweetwater.tracktrolley.com",
    name: "Sweetwater",
    variant: "primary",
    color: "#0d6efd",
    disabled: false,
  },
  {
    url: "https://doral.tracktrolley.com",
    name: "Doral",
    variant: "info",
    color: "#0dcaf0",
    disabled: false,
  },
  {
    url: "https://comb.tracktrolley.com",
    name: "Miami Beach",
    variant: "success",
    color: "#198754",
    disabled: false,
  },
  {
    url: "https://fll.tracktrolley.com",
    name: "Broward County Transit",
    variant: "danger",
    color: "#dc3545",
    disabled: false,
  },
  {
    url: "#",
    name: "Miami-Dade",
    variant: "warning",
    color: "#ffc107",
    disabled: true,
  },
];
